/* tslint:disable */
/* eslint-disable */
/**
 * Catalog Docs
 * Basic store catalog management features
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { Configuration } from './configuration';
import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from './base';

/**
 * 
 * @export
 * @interface AgentDetailedDto
 */
export interface AgentDetailedDto {
    /**
     * 
     * @type {string}
     * @memberof AgentDetailedDto
     */
    'totalPayouts': string;
    /**
     * 
     * @type {string}
     * @memberof AgentDetailedDto
     */
    'totalPayoutsWorth': string;
    /**
     * 
     * @type {string}
     * @memberof AgentDetailedDto
     */
    'totalPurchases': string;
    /**
     * 
     * @type {string}
     * @memberof AgentDetailedDto
     */
    'totalPurchasWorth': string;
    /**
     * 
     * @type {Organization}
     * @memberof AgentDetailedDto
     */
    'org': Organization;
}
/**
 * 
 * @export
 * @interface AttachAccountDto
 */
export interface AttachAccountDto {
    /**
     * 
     * @type {string}
     * @memberof AttachAccountDto
     */
    'business_name': string;
    /**
     * 
     * @type {string}
     * @memberof AttachAccountDto
     */
    'settlement_bank': string;
    /**
     * 
     * @type {string}
     * @memberof AttachAccountDto
     */
    'account_number': string;
    /**
     * 
     * @type {number}
     * @memberof AttachAccountDto
     */
    'percentage_charge': number;
    /**
     * 
     * @type {string}
     * @memberof AttachAccountDto
     */
    'primary_contact_email': string;
    /**
     * 
     * @type {string}
     * @memberof AttachAccountDto
     */
    'primary_contact_name': string;
    /**
     * 
     * @type {string}
     * @memberof AttachAccountDto
     */
    'primary_contact_phone': string;
}
/**
 * 
 * @export
 * @interface AuthenticatedLocker
 */
export interface AuthenticatedLocker {
    /**
     * 
     * @type {string}
     * @memberof AuthenticatedLocker
     */
    'token': string;
    /**
     * 
     * @type {Locker}
     * @memberof AuthenticatedLocker
     */
    'locker': Locker;
}
/**
 * 
 * @export
 * @interface Locker
 */
export interface Locker {
    /**
     * 
     * @type {string}
     * @memberof Locker
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof Locker
     */
    'type': string;
    /**
     * 
     * @type {string}
     * @memberof Locker
     */
    'status': string;
    /**
     * 
     * @type {string}
     * @memberof Locker
     */
    'first_name': string;
    /**
     * 
     * @type {string}
     * @memberof Locker
     */
    'last_name': string;
    /**
     * 
     * @type {string}
     * @memberof Locker
     */
    'middle_name': string;
    /**
     * 
     * @type {string}
     * @memberof Locker
     */
    'photo': string;
    /**
     * 
     * @type {string}
     * @memberof Locker
     */
    'pid': string;
    /**
     * 
     * @type {string}
     * @memberof Locker
     */
    'pin': string;
}
/**
 * 
 * @export
 * @interface LoginLockerDto
 */
export interface LoginLockerDto {
    /**
     * 
     * @type {string}
     * @memberof LoginLockerDto
     */
    'pid': string;
    /**
     * 
     * @type {string}
     * @memberof LoginLockerDto
     */
    'pin': string;
    /**
     * 
     * @type {string}
     * @memberof LoginLockerDto
     */
    'pos_id': string;
}
/**
 * 
 * @export
 * @interface Order
 */
export interface Order {
    /**
     * 
     * @type {Array<OrderItem>}
     * @memberof Order
     */
    'items': Array<OrderItem>;
    /**
     * 
     * @type {number}
     * @memberof Order
     */
    'sum_total': number;
    /**
     * 
     * @type {string}
     * @memberof Order
     */
    'payment_status': string;
    /**
     * 
     * @type {string}
     * @memberof Order
     */
    'payment_url': string;
    /**
     * 
     * @type {string}
     * @memberof Order
     */
    'status': string;
    /**
     * 
     * @type {string}
     * @memberof Order
     */
    'created_at': string;
    /**
     * 
     * @type {string}
     * @memberof Order
     */
    'updated_at': string;
}
/**
 * 
 * @export
 * @interface OrderItem
 */
export interface OrderItem {
    /**
     * 
     * @type {string}
     * @memberof OrderItem
     */
    'image': string;
    /**
     * 
     * @type {string}
     * @memberof OrderItem
     */
    'product_name': string;
    /**
     * 
     * @type {string}
     * @memberof OrderItem
     */
    'product_id': string;
    /**
     * 
     * @type {number}
     * @memberof OrderItem
     */
    'quantity': number;
    /**
     * 
     * @type {number}
     * @memberof OrderItem
     */
    'amount': number;
    /**
     * 
     * @type {number}
     * @memberof OrderItem
     */
    'total': number;
}
/**
 * 
 * @export
 * @interface Organization
 */
export interface Organization {
    /**
     * 
     * @type {string}
     * @memberof Organization
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof Organization
     */
    'status': string;
    /**
     * 
     * @type {string}
     * @memberof Organization
     */
    'display_name': string;
    /**
     * 
     * @type {string}
     * @memberof Organization
     */
    'caption': string;
    /**
     * 
     * @type {string}
     * @memberof Organization
     */
    'handle': string;
    /**
     * 
     * @type {string}
     * @memberof Organization
     */
    'about': string;
    /**
     * 
     * @type {string}
     * @memberof Organization
     */
    'logo': string;
    /**
     * 
     * @type {string}
     * @memberof Organization
     */
    'image': string;
    /**
     * 
     * @type {string}
     * @memberof Organization
     */
    'cover': string;
    /**
     * 
     * @type {object}
     * @memberof Organization
     */
    'paystack_int': object;
    /**
     * 
     * @type {string}
     * @memberof Organization
     */
    'created_at': string;
    /**
     * 
     * @type {string}
     * @memberof Organization
     */
    'updated_at': string;
    /**
     * 
     * @type {string}
     * @memberof Organization
     */
    'school_name': string;
    /**
     * 
     * @type {string}
     * @memberof Organization
     */
    'state': string;
    /**
     * 
     * @type {string}
     * @memberof Organization
     */
    'address': string;
    /**
     * 
     * @type {string}
     * @memberof Organization
     */
    'payment_account': string;
}
/**
 * 
 * @export
 * @interface Payout
 */
export interface Payout {
    /**
     * 
     * @type {string}
     * @memberof Payout
     */
    'notes': string;
    /**
     * 
     * @type {string}
     * @memberof Payout
     */
    'status': string;
    /**
     * 
     * @type {string}
     * @memberof Payout
     */
    'created_at': string;
    /**
     * 
     * @type {string}
     * @memberof Payout
     */
    'updated_at': string;
}
/**
 * 
 * @export
 * @interface Product
 */
export interface Product {
    /**
     * 
     * @type {string}
     * @memberof Product
     */
    'id': string;
    /**
     * 
     * @type {boolean}
     * @memberof Product
     */
    'is_trending': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Product
     */
    'is_top_product': boolean;
    /**
     * 
     * @type {string}
     * @memberof Product
     */
    'type': string;
    /**
     * 
     * @type {string}
     * @memberof Product
     */
    'status': string;
    /**
     * 
     * @type {string}
     * @memberof Product
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof Product
     */
    'body': string;
    /**
     * 
     * @type {string}
     * @memberof Product
     */
    'image': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof Product
     */
    'collections': Array<string>;
    /**
     * 
     * @type {number}
     * @memberof Product
     */
    'price': number;
    /**
     * 
     * @type {string}
     * @memberof Product
     */
    'price_formated': string;
}
/**
 * 
 * @export
 * @interface PublicProductsResponse
 */
export interface PublicProductsResponse {
    /**
     * 
     * @type {Array<Product>}
     * @memberof PublicProductsResponse
     */
    'top_products': Array<Product>;
    /**
     * 
     * @type {Array<Product>}
     * @memberof PublicProductsResponse
     */
    'is_trending': Array<Product>;
}
/**
 * 
 * @export
 * @interface PublishLockerDto
 */
export interface PublishLockerDto {
    /**
     * 
     * @type {string}
     * @memberof PublishLockerDto
     */
    'first_name': string;
    /**
     * 
     * @type {string}
     * @memberof PublishLockerDto
     */
    'last_name': string;
    /**
     * 
     * @type {string}
     * @memberof PublishLockerDto
     */
    'middle_name': string;
    /**
     * 
     * @type {string}
     * @memberof PublishLockerDto
     */
    'photo': string;
    /**
     * 
     * @type {string}
     * @memberof PublishLockerDto
     */
    'pin': string;
}
/**
 * 
 * @export
 * @interface PublishOrderDto
 */
export interface PublishOrderDto {
    /**
     * 
     * @type {Array<OrderItem>}
     * @memberof PublishOrderDto
     */
    'items': Array<OrderItem>;
    /**
     * 
     * @type {string}
     * @memberof PublishOrderDto
     */
    'locker_id': string;
}
/**
 * 
 * @export
 * @interface PublishPayoutDto
 */
export interface PublishPayoutDto {
    /**
     * 
     * @type {string}
     * @memberof PublishPayoutDto
     */
    'notes': string;
}
/**
 * 
 * @export
 * @interface PublishProductDto
 */
export interface PublishProductDto {
    /**
     * 
     * @type {string}
     * @memberof PublishProductDto
     */
    'name': string;
    /**
     * 
     * @type {number}
     * @memberof PublishProductDto
     */
    'price': number;
    /**
     * 
     * @type {string}
     * @memberof PublishProductDto
     */
    'type': PublishProductDtoTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof PublishProductDto
     */
    'status': string;
    /**
     * 
     * @type {string}
     * @memberof PublishProductDto
     */
    'body': string;
    /**
     * 
     * @type {string}
     * @memberof PublishProductDto
     */
    'image': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof PublishProductDto
     */
    'collections': Array<string>;
}

export const PublishProductDtoTypeEnum = {
    Physical: 'physical',
    Digital: 'digital'
} as const;

export type PublishProductDtoTypeEnum = typeof PublishProductDtoTypeEnum[keyof typeof PublishProductDtoTypeEnum];

/**
 * 
 * @export
 * @interface PublishPurchaseDto
 */
export interface PublishPurchaseDto {
    /**
     * 
     * @type {string}
     * @memberof PublishPurchaseDto
     */
    'purchase_token': string;
    /**
     * 
     * @type {Array<PurchaseItem>}
     * @memberof PublishPurchaseDto
     */
    'items': Array<PurchaseItem>;
}
/**
 * 
 * @export
 * @interface PublishTaxonomyDto
 */
export interface PublishTaxonomyDto {
    /**
     * 
     * @type {string}
     * @memberof PublishTaxonomyDto
     */
    'type': string;
    /**
     * 
     * @type {string}
     * @memberof PublishTaxonomyDto
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof PublishTaxonomyDto
     */
    'description': string;
    /**
     * 
     * @type {string}
     * @memberof PublishTaxonomyDto
     */
    'parent_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof PublishTaxonomyDto
     */
    'image'?: string;
    /**
     * 
     * @type {string}
     * @memberof PublishTaxonomyDto
     */
    'as'?: string;
    /**
     * 
     * @type {string}
     * @memberof PublishTaxonomyDto
     */
    'for'?: string;
}
/**
 * 
 * @export
 * @interface Purchase
 */
export interface Purchase {
    /**
     * agent id; agent through which purchase was made
     * @type {string}
     * @memberof Purchase
     */
    'publisher_id': string;
    /**
     * 
     * @type {Array<PurchaseItem>}
     * @memberof Purchase
     */
    'items': Array<PurchaseItem>;
    /**
     * 
     * @type {number}
     * @memberof Purchase
     */
    'sum_total': number;
    /**
     * 
     * @type {string}
     * @memberof Purchase
     */
    'status': string;
    /**
     * 
     * @type {boolean}
     * @memberof Purchase
     */
    'payout_requested': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Purchase
     */
    'payout_id': boolean;
    /**
     * 
     * @type {string}
     * @memberof Purchase
     */
    'created_at': string;
    /**
     * 
     * @type {string}
     * @memberof Purchase
     */
    'updated_at': string;
}
/**
 * 
 * @export
 * @interface PurchaseItem
 */
export interface PurchaseItem {
    /**
     * 
     * @type {string}
     * @memberof PurchaseItem
     */
    'image': string;
    /**
     * 
     * @type {string}
     * @memberof PurchaseItem
     */
    'product_name': string;
    /**
     * 
     * @type {string}
     * @memberof PurchaseItem
     */
    'product_id': string;
    /**
     * 
     * @type {number}
     * @memberof PurchaseItem
     */
    'quantity': number;
    /**
     * 
     * @type {number}
     * @memberof PurchaseItem
     */
    'amount': number;
}
/**
 * 
 * @export
 * @interface Taxonomy
 */
export interface Taxonomy {
    /**
     * 
     * @type {string}
     * @memberof Taxonomy
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof Taxonomy
     */
    'type': string;
    /**
     * 
     * @type {string}
     * @memberof Taxonomy
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof Taxonomy
     */
    'handle': string;
    /**
     * 
     * @type {string}
     * @memberof Taxonomy
     */
    'description': string;
    /**
     * 
     * @type {string}
     * @memberof Taxonomy
     */
    'parent_id': string;
    /**
     * 
     * @type {string}
     * @memberof Taxonomy
     */
    'image': string;
    /**
     * 
     * @type {string}
     * @memberof Taxonomy
     */
    'as': string;
    /**
     * 
     * @type {string}
     * @memberof Taxonomy
     */
    'for': string;
}
/**
 * 
 * @export
 * @interface UpdateLockerDto
 */
export interface UpdateLockerDto {
    /**
     * 
     * @type {string}
     * @memberof UpdateLockerDto
     */
    'pin': string;
}
/**
 * 
 * @export
 * @interface UpdateLockerPinDto
 */
export interface UpdateLockerPinDto {
    /**
     * 
     * @type {string}
     * @memberof UpdateLockerPinDto
     */
    'pin': string;
}
/**
 * 
 * @export
 * @interface UpdateOrganizationDto
 */
export interface UpdateOrganizationDto {
    /**
     * 
     * @type {string}
     * @memberof UpdateOrganizationDto
     */
    'status': string;
}
/**
 * 
 * @export
 * @interface UpdateProductDto
 */
export interface UpdateProductDto {
    /**
     * 
     * @type {boolean}
     * @memberof UpdateProductDto
     */
    'is_trending': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateProductDto
     */
    'is_top_product': boolean;
    /**
     * 
     * @type {string}
     * @memberof UpdateProductDto
     */
    'body': string;
    /**
     * 
     * @type {number}
     * @memberof UpdateProductDto
     */
    'price': number;
    /**
     * 
     * @type {string}
     * @memberof UpdateProductDto
     */
    'image': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof UpdateProductDto
     */
    'collections': Array<string>;
}
/**
 * 
 * @export
 * @interface UpdateProfileDto
 */
export interface UpdateProfileDto {
    /**
     * 
     * @type {string}
     * @memberof UpdateProfileDto
     */
    'photo': string;
    /**
     * 
     * @type {string}
     * @memberof UpdateProfileDto
     */
    'bio': string;
    /**
     * 
     * @type {string}
     * @memberof UpdateProfileDto
     */
    'first_name': string;
    /**
     * 
     * @type {string}
     * @memberof UpdateProfileDto
     */
    'last_name': string;
    /**
     * 
     * @type {string}
     * @memberof UpdateProfileDto
     */
    'phone': string;
}
/**
 * 
 * @export
 * @interface UpdateTaxonomyDto
 */
export interface UpdateTaxonomyDto {
    /**
     * 
     * @type {string}
     * @memberof UpdateTaxonomyDto
     */
    'type': string;
    /**
     * 
     * @type {string}
     * @memberof UpdateTaxonomyDto
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof UpdateTaxonomyDto
     */
    'description': string;
    /**
     * 
     * @type {string}
     * @memberof UpdateTaxonomyDto
     */
    'parent_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateTaxonomyDto
     */
    'image'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateTaxonomyDto
     */
    'as'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateTaxonomyDto
     */
    'for'?: string;
}
/**
 * 
 * @export
 * @interface User
 */
export interface User {
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'email': string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'phone': string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'status': string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'first_name': string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'last_name': string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'bio': string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'account_type': string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'photo': string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'password': string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'passwordResetToken': string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'passwordResetExpires': string;
    /**
     * 
     * @type {boolean}
     * @memberof User
     */
    'isActive': boolean;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'activationToken': string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'activationExpires': string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'subscription_plan': string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'subscription_status': string;
    /**
     * 
     * @type {object}
     * @memberof User
     */
    'subscription_transaction': object;
}

/**
 * LockerApi - axios parameter creator
 * @export
 */
export const LockerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        lockerControllerGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/catalog/Lockers`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} item 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        lockerControllerGetById: async (item: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'item' is not null or undefined
            assertParamExists('lockerControllerGetById', 'item', item)
            const localVarPath = `/api/catalog/Lockers/{item}`
                .replace(`{${"item"}}`, encodeURIComponent(String(item)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} pid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        lockerControllerGetLocker: async (pid: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'pid' is not null or undefined
            assertParamExists('lockerControllerGetLocker', 'pid', pid)
            const localVarPath = `/api/catalog/Lockers/find-locker-by-pid`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (pid !== undefined) {
                localVarQueryParameter['pid'] = pid;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        lockerControllerGetLockerAdmin: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/catalog/Lockers/admin`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {LoginLockerDto} loginLockerDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        lockerControllerLoginLocker: async (loginLockerDto: LoginLockerDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'loginLockerDto' is not null or undefined
            assertParamExists('lockerControllerLoginLocker', 'loginLockerDto', loginLockerDto)
            const localVarPath = `/api/catalog/Lockers/login-locker`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(loginLockerDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {PublishLockerDto} publishLockerDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        lockerControllerPublish: async (publishLockerDto: PublishLockerDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'publishLockerDto' is not null or undefined
            assertParamExists('lockerControllerPublish', 'publishLockerDto', publishLockerDto)
            const localVarPath = `/api/catalog/Lockers`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(publishLockerDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {UpdateLockerPinDto} updateLockerPinDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        lockerControllerResetLockerPassword: async (id: string, updateLockerPinDto: UpdateLockerPinDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('lockerControllerResetLockerPassword', 'id', id)
            // verify required parameter 'updateLockerPinDto' is not null or undefined
            assertParamExists('lockerControllerResetLockerPassword', 'updateLockerPinDto', updateLockerPinDto)
            const localVarPath = `/api/catalog/Lockers/reset-locker-password`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateLockerPinDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} item 
         * @param {UpdateLockerDto} updateLockerDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        lockerControllerUpdate: async (item: string, updateLockerDto: UpdateLockerDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'item' is not null or undefined
            assertParamExists('lockerControllerUpdate', 'item', item)
            // verify required parameter 'updateLockerDto' is not null or undefined
            assertParamExists('lockerControllerUpdate', 'updateLockerDto', updateLockerDto)
            const localVarPath = `/api/catalog/Lockers/{item}`
                .replace(`{${"item"}}`, encodeURIComponent(String(item)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateLockerDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * LockerApi - functional programming interface
 * @export
 */
export const LockerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = LockerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async lockerControllerGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Locker>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.lockerControllerGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} item 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async lockerControllerGetById(item: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Locker>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.lockerControllerGetById(item, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} pid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async lockerControllerGetLocker(pid: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Locker>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.lockerControllerGetLocker(pid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async lockerControllerGetLockerAdmin(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Locker>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.lockerControllerGetLockerAdmin(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {LoginLockerDto} loginLockerDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async lockerControllerLoginLocker(loginLockerDto: LoginLockerDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AuthenticatedLocker>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.lockerControllerLoginLocker(loginLockerDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {PublishLockerDto} publishLockerDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async lockerControllerPublish(publishLockerDto: PublishLockerDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Locker>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.lockerControllerPublish(publishLockerDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {UpdateLockerPinDto} updateLockerPinDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async lockerControllerResetLockerPassword(id: string, updateLockerPinDto: UpdateLockerPinDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AuthenticatedLocker>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.lockerControllerResetLockerPassword(id, updateLockerPinDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} item 
         * @param {UpdateLockerDto} updateLockerDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async lockerControllerUpdate(item: string, updateLockerDto: UpdateLockerDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Locker>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.lockerControllerUpdate(item, updateLockerDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * LockerApi - factory interface
 * @export
 */
export const LockerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = LockerApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        lockerControllerGet(options?: any): AxiosPromise<Array<Locker>> {
            return localVarFp.lockerControllerGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} item 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        lockerControllerGetById(item: string, options?: any): AxiosPromise<Locker> {
            return localVarFp.lockerControllerGetById(item, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} pid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        lockerControllerGetLocker(pid: string, options?: any): AxiosPromise<Locker> {
            return localVarFp.lockerControllerGetLocker(pid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        lockerControllerGetLockerAdmin(options?: any): AxiosPromise<Array<Locker>> {
            return localVarFp.lockerControllerGetLockerAdmin(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {LoginLockerDto} loginLockerDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        lockerControllerLoginLocker(loginLockerDto: LoginLockerDto, options?: any): AxiosPromise<AuthenticatedLocker> {
            return localVarFp.lockerControllerLoginLocker(loginLockerDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {PublishLockerDto} publishLockerDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        lockerControllerPublish(publishLockerDto: PublishLockerDto, options?: any): AxiosPromise<Locker> {
            return localVarFp.lockerControllerPublish(publishLockerDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {UpdateLockerPinDto} updateLockerPinDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        lockerControllerResetLockerPassword(id: string, updateLockerPinDto: UpdateLockerPinDto, options?: any): AxiosPromise<AuthenticatedLocker> {
            return localVarFp.lockerControllerResetLockerPassword(id, updateLockerPinDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} item 
         * @param {UpdateLockerDto} updateLockerDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        lockerControllerUpdate(item: string, updateLockerDto: UpdateLockerDto, options?: any): AxiosPromise<Locker> {
            return localVarFp.lockerControllerUpdate(item, updateLockerDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * LockerApi - object-oriented interface
 * @export
 * @class LockerApi
 * @extends {BaseAPI}
 */
export class LockerApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LockerApi
     */
    public lockerControllerGet(options?: AxiosRequestConfig) {
        return LockerApiFp(this.configuration).lockerControllerGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} item 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LockerApi
     */
    public lockerControllerGetById(item: string, options?: AxiosRequestConfig) {
        return LockerApiFp(this.configuration).lockerControllerGetById(item, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} pid 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LockerApi
     */
    public lockerControllerGetLocker(pid: string, options?: AxiosRequestConfig) {
        return LockerApiFp(this.configuration).lockerControllerGetLocker(pid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LockerApi
     */
    public lockerControllerGetLockerAdmin(options?: AxiosRequestConfig) {
        return LockerApiFp(this.configuration).lockerControllerGetLockerAdmin(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {LoginLockerDto} loginLockerDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LockerApi
     */
    public lockerControllerLoginLocker(loginLockerDto: LoginLockerDto, options?: AxiosRequestConfig) {
        return LockerApiFp(this.configuration).lockerControllerLoginLocker(loginLockerDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {PublishLockerDto} publishLockerDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LockerApi
     */
    public lockerControllerPublish(publishLockerDto: PublishLockerDto, options?: AxiosRequestConfig) {
        return LockerApiFp(this.configuration).lockerControllerPublish(publishLockerDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {UpdateLockerPinDto} updateLockerPinDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LockerApi
     */
    public lockerControllerResetLockerPassword(id: string, updateLockerPinDto: UpdateLockerPinDto, options?: AxiosRequestConfig) {
        return LockerApiFp(this.configuration).lockerControllerResetLockerPassword(id, updateLockerPinDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} item 
     * @param {UpdateLockerDto} updateLockerDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LockerApi
     */
    public lockerControllerUpdate(item: string, updateLockerDto: UpdateLockerDto, options?: AxiosRequestConfig) {
        return LockerApiFp(this.configuration).lockerControllerUpdate(item, updateLockerDto, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * OrderControllerApi - axios parameter creator
 * @export
 */
export const OrderControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orderControllerGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/catalog/order`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} item 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orderControllerGetById: async (item: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'item' is not null or undefined
            assertParamExists('orderControllerGetById', 'item', item)
            const localVarPath = `/api/catalog/order/{item}`
                .replace(`{${"item"}}`, encodeURIComponent(String(item)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orderControllerGetOrderAdmin: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/catalog/order/admin`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} item 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orderControllerPay: async (item: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'item' is not null or undefined
            assertParamExists('orderControllerPay', 'item', item)
            const localVarPath = `/api/catalog/order/{item}/pay`
                .replace(`{${"item"}}`, encodeURIComponent(String(item)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {PublishOrderDto} publishOrderDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orderControllerPublish: async (publishOrderDto: PublishOrderDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'publishOrderDto' is not null or undefined
            assertParamExists('orderControllerPublish', 'publishOrderDto', publishOrderDto)
            const localVarPath = `/api/catalog/order`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(publishOrderDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} item 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orderControllerSynce: async (item: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'item' is not null or undefined
            assertParamExists('orderControllerSynce', 'item', item)
            const localVarPath = `/api/catalog/order/{item}/sync`
                .replace(`{${"item"}}`, encodeURIComponent(String(item)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * OrderControllerApi - functional programming interface
 * @export
 */
export const OrderControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = OrderControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async orderControllerGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Order>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.orderControllerGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} item 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async orderControllerGetById(item: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Order>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.orderControllerGetById(item, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async orderControllerGetOrderAdmin(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Order>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.orderControllerGetOrderAdmin(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} item 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async orderControllerPay(item: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Order>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.orderControllerPay(item, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {PublishOrderDto} publishOrderDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async orderControllerPublish(publishOrderDto: PublishOrderDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Order>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.orderControllerPublish(publishOrderDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} item 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async orderControllerSynce(item: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Order>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.orderControllerSynce(item, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * OrderControllerApi - factory interface
 * @export
 */
export const OrderControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = OrderControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orderControllerGet(options?: any): AxiosPromise<Array<Order>> {
            return localVarFp.orderControllerGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} item 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orderControllerGetById(item: string, options?: any): AxiosPromise<Order> {
            return localVarFp.orderControllerGetById(item, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orderControllerGetOrderAdmin(options?: any): AxiosPromise<Array<Order>> {
            return localVarFp.orderControllerGetOrderAdmin(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} item 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orderControllerPay(item: string, options?: any): AxiosPromise<Order> {
            return localVarFp.orderControllerPay(item, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {PublishOrderDto} publishOrderDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orderControllerPublish(publishOrderDto: PublishOrderDto, options?: any): AxiosPromise<Order> {
            return localVarFp.orderControllerPublish(publishOrderDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} item 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orderControllerSynce(item: string, options?: any): AxiosPromise<Order> {
            return localVarFp.orderControllerSynce(item, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * OrderControllerApi - object-oriented interface
 * @export
 * @class OrderControllerApi
 * @extends {BaseAPI}
 */
export class OrderControllerApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrderControllerApi
     */
    public orderControllerGet(options?: AxiosRequestConfig) {
        return OrderControllerApiFp(this.configuration).orderControllerGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} item 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrderControllerApi
     */
    public orderControllerGetById(item: string, options?: AxiosRequestConfig) {
        return OrderControllerApiFp(this.configuration).orderControllerGetById(item, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrderControllerApi
     */
    public orderControllerGetOrderAdmin(options?: AxiosRequestConfig) {
        return OrderControllerApiFp(this.configuration).orderControllerGetOrderAdmin(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} item 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrderControllerApi
     */
    public orderControllerPay(item: string, options?: AxiosRequestConfig) {
        return OrderControllerApiFp(this.configuration).orderControllerPay(item, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {PublishOrderDto} publishOrderDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrderControllerApi
     */
    public orderControllerPublish(publishOrderDto: PublishOrderDto, options?: AxiosRequestConfig) {
        return OrderControllerApiFp(this.configuration).orderControllerPublish(publishOrderDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} item 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrderControllerApi
     */
    public orderControllerSynce(item: string, options?: AxiosRequestConfig) {
        return OrderControllerApiFp(this.configuration).orderControllerSynce(item, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * OrgControllerApi - axios parameter creator
 * @export
 */
export const OrgControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} item 
         * @param {AttachAccountDto} attachAccountDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orgControllerAttachBankAccount: async (item: string, attachAccountDto: AttachAccountDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'item' is not null or undefined
            assertParamExists('orgControllerAttachBankAccount', 'item', item)
            // verify required parameter 'attachAccountDto' is not null or undefined
            assertParamExists('orgControllerAttachBankAccount', 'attachAccountDto', attachAccountDto)
            const localVarPath = `/api/catalog/org/{item}`
                .replace(`{${"item"}}`, encodeURIComponent(String(item)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(attachAccountDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} item 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orgControllerGetOrg: async (item: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'item' is not null or undefined
            assertParamExists('orgControllerGetOrg', 'item', item)
            const localVarPath = `/api/catalog/org/{item}/profile`
                .replace(`{${"item"}}`, encodeURIComponent(String(item)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {UpdateOrganizationDto} updateOrganizationDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orgControllerGetOrgAdmin: async (updateOrganizationDto: UpdateOrganizationDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'updateOrganizationDto' is not null or undefined
            assertParamExists('orgControllerGetOrgAdmin', 'updateOrganizationDto', updateOrganizationDto)
            const localVarPath = `/api/catalog/org/admin`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateOrganizationDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * OrgControllerApi - functional programming interface
 * @export
 */
export const OrgControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = OrgControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} item 
         * @param {AttachAccountDto} attachAccountDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async orgControllerAttachBankAccount(item: string, attachAccountDto: AttachAccountDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Organization>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.orgControllerAttachBankAccount(item, attachAccountDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} item 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async orgControllerGetOrg(item: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AgentDetailedDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.orgControllerGetOrg(item, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {UpdateOrganizationDto} updateOrganizationDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async orgControllerGetOrgAdmin(updateOrganizationDto: UpdateOrganizationDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Organization>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.orgControllerGetOrgAdmin(updateOrganizationDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * OrgControllerApi - factory interface
 * @export
 */
export const OrgControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = OrgControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {string} item 
         * @param {AttachAccountDto} attachAccountDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orgControllerAttachBankAccount(item: string, attachAccountDto: AttachAccountDto, options?: any): AxiosPromise<Organization> {
            return localVarFp.orgControllerAttachBankAccount(item, attachAccountDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} item 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orgControllerGetOrg(item: string, options?: any): AxiosPromise<AgentDetailedDto> {
            return localVarFp.orgControllerGetOrg(item, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {UpdateOrganizationDto} updateOrganizationDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orgControllerGetOrgAdmin(updateOrganizationDto: UpdateOrganizationDto, options?: any): AxiosPromise<Organization> {
            return localVarFp.orgControllerGetOrgAdmin(updateOrganizationDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * OrgControllerApi - object-oriented interface
 * @export
 * @class OrgControllerApi
 * @extends {BaseAPI}
 */
export class OrgControllerApi extends BaseAPI {
    /**
     * 
     * @param {string} item 
     * @param {AttachAccountDto} attachAccountDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrgControllerApi
     */
    public orgControllerAttachBankAccount(item: string, attachAccountDto: AttachAccountDto, options?: AxiosRequestConfig) {
        return OrgControllerApiFp(this.configuration).orgControllerAttachBankAccount(item, attachAccountDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} item 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrgControllerApi
     */
    public orgControllerGetOrg(item: string, options?: AxiosRequestConfig) {
        return OrgControllerApiFp(this.configuration).orgControllerGetOrg(item, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {UpdateOrganizationDto} updateOrganizationDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrgControllerApi
     */
    public orgControllerGetOrgAdmin(updateOrganizationDto: UpdateOrganizationDto, options?: AxiosRequestConfig) {
        return OrgControllerApiFp(this.configuration).orgControllerGetOrgAdmin(updateOrganizationDto, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * PayoutsControllerApi - axios parameter creator
 * @export
 */
export const PayoutsControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} item 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        payoutControllerApprovePayout: async (item: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'item' is not null or undefined
            assertParamExists('payoutControllerApprovePayout', 'item', item)
            const localVarPath = `/api/catalog/payouts/{item}/approve`
                .replace(`{${"item"}}`, encodeURIComponent(String(item)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} item 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        payoutControllerClearPayout: async (item: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'item' is not null or undefined
            assertParamExists('payoutControllerClearPayout', 'item', item)
            const localVarPath = `/api/catalog/payouts/{item}/clear`
                .replace(`{${"item"}}`, encodeURIComponent(String(item)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        payoutControllerGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/catalog/payouts`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} item 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        payoutControllerGetById: async (item: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'item' is not null or undefined
            assertParamExists('payoutControllerGetById', 'item', item)
            const localVarPath = `/api/catalog/payouts/{item}`
                .replace(`{${"item"}}`, encodeURIComponent(String(item)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        payoutControllerGetPayoutAdmin: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/catalog/payouts/admin`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {PublishPayoutDto} publishPayoutDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        payoutControllerPublish: async (publishPayoutDto: PublishPayoutDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'publishPayoutDto' is not null or undefined
            assertParamExists('payoutControllerPublish', 'publishPayoutDto', publishPayoutDto)
            const localVarPath = `/api/catalog/payouts/request-payout`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(publishPayoutDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} item 
         * @param {object} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        payoutControllerUpdate: async (item: string, body: object, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'item' is not null or undefined
            assertParamExists('payoutControllerUpdate', 'item', item)
            // verify required parameter 'body' is not null or undefined
            assertParamExists('payoutControllerUpdate', 'body', body)
            const localVarPath = `/api/catalog/payouts/{item}`
                .replace(`{${"item"}}`, encodeURIComponent(String(item)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PayoutsControllerApi - functional programming interface
 * @export
 */
export const PayoutsControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PayoutsControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} item 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async payoutControllerApprovePayout(item: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Payout>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.payoutControllerApprovePayout(item, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} item 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async payoutControllerClearPayout(item: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Payout>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.payoutControllerClearPayout(item, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async payoutControllerGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Payout>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.payoutControllerGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} item 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async payoutControllerGetById(item: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Payout>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.payoutControllerGetById(item, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async payoutControllerGetPayoutAdmin(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Payout>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.payoutControllerGetPayoutAdmin(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {PublishPayoutDto} publishPayoutDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async payoutControllerPublish(publishPayoutDto: PublishPayoutDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Payout>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.payoutControllerPublish(publishPayoutDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} item 
         * @param {object} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async payoutControllerUpdate(item: string, body: object, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Payout>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.payoutControllerUpdate(item, body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * PayoutsControllerApi - factory interface
 * @export
 */
export const PayoutsControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PayoutsControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {string} item 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        payoutControllerApprovePayout(item: string, options?: any): AxiosPromise<Payout> {
            return localVarFp.payoutControllerApprovePayout(item, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} item 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        payoutControllerClearPayout(item: string, options?: any): AxiosPromise<Payout> {
            return localVarFp.payoutControllerClearPayout(item, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        payoutControllerGet(options?: any): AxiosPromise<Array<Payout>> {
            return localVarFp.payoutControllerGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} item 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        payoutControllerGetById(item: string, options?: any): AxiosPromise<Payout> {
            return localVarFp.payoutControllerGetById(item, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        payoutControllerGetPayoutAdmin(options?: any): AxiosPromise<Array<Payout>> {
            return localVarFp.payoutControllerGetPayoutAdmin(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {PublishPayoutDto} publishPayoutDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        payoutControllerPublish(publishPayoutDto: PublishPayoutDto, options?: any): AxiosPromise<Payout> {
            return localVarFp.payoutControllerPublish(publishPayoutDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} item 
         * @param {object} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        payoutControllerUpdate(item: string, body: object, options?: any): AxiosPromise<Payout> {
            return localVarFp.payoutControllerUpdate(item, body, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PayoutsControllerApi - object-oriented interface
 * @export
 * @class PayoutsControllerApi
 * @extends {BaseAPI}
 */
export class PayoutsControllerApi extends BaseAPI {
    /**
     * 
     * @param {string} item 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PayoutsControllerApi
     */
    public payoutControllerApprovePayout(item: string, options?: AxiosRequestConfig) {
        return PayoutsControllerApiFp(this.configuration).payoutControllerApprovePayout(item, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} item 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PayoutsControllerApi
     */
    public payoutControllerClearPayout(item: string, options?: AxiosRequestConfig) {
        return PayoutsControllerApiFp(this.configuration).payoutControllerClearPayout(item, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PayoutsControllerApi
     */
    public payoutControllerGet(options?: AxiosRequestConfig) {
        return PayoutsControllerApiFp(this.configuration).payoutControllerGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} item 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PayoutsControllerApi
     */
    public payoutControllerGetById(item: string, options?: AxiosRequestConfig) {
        return PayoutsControllerApiFp(this.configuration).payoutControllerGetById(item, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PayoutsControllerApi
     */
    public payoutControllerGetPayoutAdmin(options?: AxiosRequestConfig) {
        return PayoutsControllerApiFp(this.configuration).payoutControllerGetPayoutAdmin(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {PublishPayoutDto} publishPayoutDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PayoutsControllerApi
     */
    public payoutControllerPublish(publishPayoutDto: PublishPayoutDto, options?: AxiosRequestConfig) {
        return PayoutsControllerApiFp(this.configuration).payoutControllerPublish(publishPayoutDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} item 
     * @param {object} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PayoutsControllerApi
     */
    public payoutControllerUpdate(item: string, body: object, options?: AxiosRequestConfig) {
        return PayoutsControllerApiFp(this.configuration).payoutControllerUpdate(item, body, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ProductApi - axios parameter creator
 * @export
 */
export const ProductApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} item 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productControllerDeleteItem: async (item: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'item' is not null or undefined
            assertParamExists('productControllerDeleteItem', 'item', item)
            const localVarPath = `/api/catalog/products/{item}`
                .replace(`{${"item"}}`, encodeURIComponent(String(item)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productControllerGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/catalog/products`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} item 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productControllerGetById: async (item: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'item' is not null or undefined
            assertParamExists('productControllerGetById', 'item', item)
            const localVarPath = `/api/catalog/products/{item}`
                .replace(`{${"item"}}`, encodeURIComponent(String(item)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} category 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productControllerGetProductsFiltered: async (category: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'category' is not null or undefined
            assertParamExists('productControllerGetProductsFiltered', 'category', category)
            const localVarPath = `/api/catalog/products/products-filtered`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (category !== undefined) {
                localVarQueryParameter['category'] = category;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productControllerGetPublic: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/catalog/products/public`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {PublishProductDto} publishProductDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productControllerPublish: async (publishProductDto: PublishProductDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'publishProductDto' is not null or undefined
            assertParamExists('productControllerPublish', 'publishProductDto', publishProductDto)
            const localVarPath = `/api/catalog/products`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(publishProductDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} item 
         * @param {UpdateProductDto} updateProductDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productControllerUpdate: async (item: string, updateProductDto: UpdateProductDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'item' is not null or undefined
            assertParamExists('productControllerUpdate', 'item', item)
            // verify required parameter 'updateProductDto' is not null or undefined
            assertParamExists('productControllerUpdate', 'updateProductDto', updateProductDto)
            const localVarPath = `/api/catalog/products/{item}`
                .replace(`{${"item"}}`, encodeURIComponent(String(item)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateProductDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ProductApi - functional programming interface
 * @export
 */
export const ProductApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ProductApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} item 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async productControllerDeleteItem(item: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.productControllerDeleteItem(item, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async productControllerGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Product>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.productControllerGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} item 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async productControllerGetById(item: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Product>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.productControllerGetById(item, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} category 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async productControllerGetProductsFiltered(category: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Product>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.productControllerGetProductsFiltered(category, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async productControllerGetPublic(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PublicProductsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.productControllerGetPublic(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {PublishProductDto} publishProductDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async productControllerPublish(publishProductDto: PublishProductDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Product>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.productControllerPublish(publishProductDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} item 
         * @param {UpdateProductDto} updateProductDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async productControllerUpdate(item: string, updateProductDto: UpdateProductDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Product>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.productControllerUpdate(item, updateProductDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ProductApi - factory interface
 * @export
 */
export const ProductApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ProductApiFp(configuration)
    return {
        /**
         * 
         * @param {string} item 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productControllerDeleteItem(item: string, options?: any): AxiosPromise<void> {
            return localVarFp.productControllerDeleteItem(item, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productControllerGet(options?: any): AxiosPromise<Array<Product>> {
            return localVarFp.productControllerGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} item 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productControllerGetById(item: string, options?: any): AxiosPromise<Product> {
            return localVarFp.productControllerGetById(item, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} category 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productControllerGetProductsFiltered(category: string, options?: any): AxiosPromise<Array<Product>> {
            return localVarFp.productControllerGetProductsFiltered(category, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productControllerGetPublic(options?: any): AxiosPromise<PublicProductsResponse> {
            return localVarFp.productControllerGetPublic(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {PublishProductDto} publishProductDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productControllerPublish(publishProductDto: PublishProductDto, options?: any): AxiosPromise<Product> {
            return localVarFp.productControllerPublish(publishProductDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} item 
         * @param {UpdateProductDto} updateProductDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productControllerUpdate(item: string, updateProductDto: UpdateProductDto, options?: any): AxiosPromise<Product> {
            return localVarFp.productControllerUpdate(item, updateProductDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ProductApi - object-oriented interface
 * @export
 * @class ProductApi
 * @extends {BaseAPI}
 */
export class ProductApi extends BaseAPI {
    /**
     * 
     * @param {string} item 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductApi
     */
    public productControllerDeleteItem(item: string, options?: AxiosRequestConfig) {
        return ProductApiFp(this.configuration).productControllerDeleteItem(item, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductApi
     */
    public productControllerGet(options?: AxiosRequestConfig) {
        return ProductApiFp(this.configuration).productControllerGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} item 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductApi
     */
    public productControllerGetById(item: string, options?: AxiosRequestConfig) {
        return ProductApiFp(this.configuration).productControllerGetById(item, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} category 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductApi
     */
    public productControllerGetProductsFiltered(category: string, options?: AxiosRequestConfig) {
        return ProductApiFp(this.configuration).productControllerGetProductsFiltered(category, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductApi
     */
    public productControllerGetPublic(options?: AxiosRequestConfig) {
        return ProductApiFp(this.configuration).productControllerGetPublic(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {PublishProductDto} publishProductDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductApi
     */
    public productControllerPublish(publishProductDto: PublishProductDto, options?: AxiosRequestConfig) {
        return ProductApiFp(this.configuration).productControllerPublish(publishProductDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} item 
     * @param {UpdateProductDto} updateProductDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductApi
     */
    public productControllerUpdate(item: string, updateProductDto: UpdateProductDto, options?: AxiosRequestConfig) {
        return ProductApiFp(this.configuration).productControllerUpdate(item, updateProductDto, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * PurchaseControllerApi - axios parameter creator
 * @export
 */
export const PurchaseControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        purchaseControllerGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/catalog/Purchase`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} item 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        purchaseControllerGetById: async (item: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'item' is not null or undefined
            assertParamExists('purchaseControllerGetById', 'item', item)
            const localVarPath = `/api/catalog/Purchase/{item}`
                .replace(`{${"item"}}`, encodeURIComponent(String(item)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        purchaseControllerGetPurchasesAdmin: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/catalog/Purchase/admin`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} lockerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        purchaseControllerGetPurchasesLocker: async (lockerId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'lockerId' is not null or undefined
            assertParamExists('purchaseControllerGetPurchasesLocker', 'lockerId', lockerId)
            const localVarPath = `/api/catalog/Purchase/get-locker-purchases`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (lockerId !== undefined) {
                localVarQueryParameter['locker_id'] = lockerId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {PublishPurchaseDto} publishPurchaseDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        purchaseControllerPublish: async (publishPurchaseDto: PublishPurchaseDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'publishPurchaseDto' is not null or undefined
            assertParamExists('purchaseControllerPublish', 'publishPurchaseDto', publishPurchaseDto)
            const localVarPath = `/api/catalog/Purchase/make-purchase`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(publishPurchaseDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PurchaseControllerApi - functional programming interface
 * @export
 */
export const PurchaseControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PurchaseControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async purchaseControllerGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Purchase>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.purchaseControllerGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} item 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async purchaseControllerGetById(item: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Purchase>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.purchaseControllerGetById(item, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async purchaseControllerGetPurchasesAdmin(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Purchase>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.purchaseControllerGetPurchasesAdmin(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} lockerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async purchaseControllerGetPurchasesLocker(lockerId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Purchase>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.purchaseControllerGetPurchasesLocker(lockerId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {PublishPurchaseDto} publishPurchaseDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async purchaseControllerPublish(publishPurchaseDto: PublishPurchaseDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Purchase>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.purchaseControllerPublish(publishPurchaseDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * PurchaseControllerApi - factory interface
 * @export
 */
export const PurchaseControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PurchaseControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        purchaseControllerGet(options?: any): AxiosPromise<Array<Purchase>> {
            return localVarFp.purchaseControllerGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} item 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        purchaseControllerGetById(item: string, options?: any): AxiosPromise<Purchase> {
            return localVarFp.purchaseControllerGetById(item, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        purchaseControllerGetPurchasesAdmin(options?: any): AxiosPromise<Array<Purchase>> {
            return localVarFp.purchaseControllerGetPurchasesAdmin(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} lockerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        purchaseControllerGetPurchasesLocker(lockerId: string, options?: any): AxiosPromise<Array<Purchase>> {
            return localVarFp.purchaseControllerGetPurchasesLocker(lockerId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {PublishPurchaseDto} publishPurchaseDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        purchaseControllerPublish(publishPurchaseDto: PublishPurchaseDto, options?: any): AxiosPromise<Purchase> {
            return localVarFp.purchaseControllerPublish(publishPurchaseDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PurchaseControllerApi - object-oriented interface
 * @export
 * @class PurchaseControllerApi
 * @extends {BaseAPI}
 */
export class PurchaseControllerApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PurchaseControllerApi
     */
    public purchaseControllerGet(options?: AxiosRequestConfig) {
        return PurchaseControllerApiFp(this.configuration).purchaseControllerGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} item 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PurchaseControllerApi
     */
    public purchaseControllerGetById(item: string, options?: AxiosRequestConfig) {
        return PurchaseControllerApiFp(this.configuration).purchaseControllerGetById(item, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PurchaseControllerApi
     */
    public purchaseControllerGetPurchasesAdmin(options?: AxiosRequestConfig) {
        return PurchaseControllerApiFp(this.configuration).purchaseControllerGetPurchasesAdmin(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} lockerId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PurchaseControllerApi
     */
    public purchaseControllerGetPurchasesLocker(lockerId: string, options?: AxiosRequestConfig) {
        return PurchaseControllerApiFp(this.configuration).purchaseControllerGetPurchasesLocker(lockerId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {PublishPurchaseDto} publishPurchaseDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PurchaseControllerApi
     */
    public purchaseControllerPublish(publishPurchaseDto: PublishPurchaseDto, options?: AxiosRequestConfig) {
        return PurchaseControllerApiFp(this.configuration).purchaseControllerPublish(publishPurchaseDto, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * TaxonomyApi - axios parameter creator
 * @export
 */
export const TaxonomyApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} item 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        taxonomyControllerDeleteItem: async (item: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'item' is not null or undefined
            assertParamExists('taxonomyControllerDeleteItem', 'item', item)
            const localVarPath = `/api/catalog/taxonomy/{item}`
                .replace(`{${"item"}}`, encodeURIComponent(String(item)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        taxonomyControllerGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/catalog/taxonomy`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} item 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        taxonomyControllerGetById: async (item: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'item' is not null or undefined
            assertParamExists('taxonomyControllerGetById', 'item', item)
            const localVarPath = `/api/catalog/taxonomy/{item}`
                .replace(`{${"item"}}`, encodeURIComponent(String(item)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {PublishTaxonomyDto} publishTaxonomyDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        taxonomyControllerPublish: async (publishTaxonomyDto: PublishTaxonomyDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'publishTaxonomyDto' is not null or undefined
            assertParamExists('taxonomyControllerPublish', 'publishTaxonomyDto', publishTaxonomyDto)
            const localVarPath = `/api/catalog/taxonomy`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(publishTaxonomyDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} item 
         * @param {UpdateTaxonomyDto} updateTaxonomyDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        taxonomyControllerUpdate: async (item: string, updateTaxonomyDto: UpdateTaxonomyDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'item' is not null or undefined
            assertParamExists('taxonomyControllerUpdate', 'item', item)
            // verify required parameter 'updateTaxonomyDto' is not null or undefined
            assertParamExists('taxonomyControllerUpdate', 'updateTaxonomyDto', updateTaxonomyDto)
            const localVarPath = `/api/catalog/taxonomy/{item}`
                .replace(`{${"item"}}`, encodeURIComponent(String(item)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateTaxonomyDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * TaxonomyApi - functional programming interface
 * @export
 */
export const TaxonomyApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = TaxonomyApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} item 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async taxonomyControllerDeleteItem(item: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.taxonomyControllerDeleteItem(item, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async taxonomyControllerGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Taxonomy>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.taxonomyControllerGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} item 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async taxonomyControllerGetById(item: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Taxonomy>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.taxonomyControllerGetById(item, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {PublishTaxonomyDto} publishTaxonomyDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async taxonomyControllerPublish(publishTaxonomyDto: PublishTaxonomyDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Taxonomy>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.taxonomyControllerPublish(publishTaxonomyDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} item 
         * @param {UpdateTaxonomyDto} updateTaxonomyDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async taxonomyControllerUpdate(item: string, updateTaxonomyDto: UpdateTaxonomyDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Taxonomy>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.taxonomyControllerUpdate(item, updateTaxonomyDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * TaxonomyApi - factory interface
 * @export
 */
export const TaxonomyApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = TaxonomyApiFp(configuration)
    return {
        /**
         * 
         * @param {string} item 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        taxonomyControllerDeleteItem(item: string, options?: any): AxiosPromise<void> {
            return localVarFp.taxonomyControllerDeleteItem(item, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        taxonomyControllerGet(options?: any): AxiosPromise<Array<Taxonomy>> {
            return localVarFp.taxonomyControllerGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} item 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        taxonomyControllerGetById(item: string, options?: any): AxiosPromise<Taxonomy> {
            return localVarFp.taxonomyControllerGetById(item, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {PublishTaxonomyDto} publishTaxonomyDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        taxonomyControllerPublish(publishTaxonomyDto: PublishTaxonomyDto, options?: any): AxiosPromise<Taxonomy> {
            return localVarFp.taxonomyControllerPublish(publishTaxonomyDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} item 
         * @param {UpdateTaxonomyDto} updateTaxonomyDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        taxonomyControllerUpdate(item: string, updateTaxonomyDto: UpdateTaxonomyDto, options?: any): AxiosPromise<Taxonomy> {
            return localVarFp.taxonomyControllerUpdate(item, updateTaxonomyDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * TaxonomyApi - object-oriented interface
 * @export
 * @class TaxonomyApi
 * @extends {BaseAPI}
 */
export class TaxonomyApi extends BaseAPI {
    /**
     * 
     * @param {string} item 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TaxonomyApi
     */
    public taxonomyControllerDeleteItem(item: string, options?: AxiosRequestConfig) {
        return TaxonomyApiFp(this.configuration).taxonomyControllerDeleteItem(item, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TaxonomyApi
     */
    public taxonomyControllerGet(options?: AxiosRequestConfig) {
        return TaxonomyApiFp(this.configuration).taxonomyControllerGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} item 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TaxonomyApi
     */
    public taxonomyControllerGetById(item: string, options?: AxiosRequestConfig) {
        return TaxonomyApiFp(this.configuration).taxonomyControllerGetById(item, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {PublishTaxonomyDto} publishTaxonomyDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TaxonomyApi
     */
    public taxonomyControllerPublish(publishTaxonomyDto: PublishTaxonomyDto, options?: AxiosRequestConfig) {
        return TaxonomyApiFp(this.configuration).taxonomyControllerPublish(publishTaxonomyDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} item 
     * @param {UpdateTaxonomyDto} updateTaxonomyDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TaxonomyApi
     */
    public taxonomyControllerUpdate(item: string, updateTaxonomyDto: UpdateTaxonomyDto, options?: AxiosRequestConfig) {
        return TaxonomyApiFp(this.configuration).taxonomyControllerUpdate(item, updateTaxonomyDto, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * UsersControllerApi - axios parameter creator
 * @export
 */
export const UsersControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userControllerGetAgents: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/users/get-agents`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userControllerGetIndividuals: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/users/get-individuals`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} item 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userControllerGetProfile: async (item: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'item' is not null or undefined
            assertParamExists('userControllerGetProfile', 'item', item)
            const localVarPath = `/api/users/{item}`
                .replace(`{${"item"}}`, encodeURIComponent(String(item)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userControllerGetSystemUsers: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/users/get-system-users`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} item 
         * @param {UpdateProfileDto} updateProfileDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userControllerUpdateUserProfile: async (item: string, updateProfileDto: UpdateProfileDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'item' is not null or undefined
            assertParamExists('userControllerUpdateUserProfile', 'item', item)
            // verify required parameter 'updateProfileDto' is not null or undefined
            assertParamExists('userControllerUpdateUserProfile', 'updateProfileDto', updateProfileDto)
            const localVarPath = `/api/users/{item}`
                .replace(`{${"item"}}`, encodeURIComponent(String(item)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateProfileDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UsersControllerApi - functional programming interface
 * @export
 */
export const UsersControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = UsersControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userControllerGetAgents(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<User>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.userControllerGetAgents(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userControllerGetIndividuals(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<User>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.userControllerGetIndividuals(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} item 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userControllerGetProfile(item: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<User>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.userControllerGetProfile(item, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userControllerGetSystemUsers(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<User>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.userControllerGetSystemUsers(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} item 
         * @param {UpdateProfileDto} updateProfileDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userControllerUpdateUserProfile(item: string, updateProfileDto: UpdateProfileDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<User>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.userControllerUpdateUserProfile(item, updateProfileDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * UsersControllerApi - factory interface
 * @export
 */
export const UsersControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = UsersControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userControllerGetAgents(options?: any): AxiosPromise<Array<User>> {
            return localVarFp.userControllerGetAgents(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userControllerGetIndividuals(options?: any): AxiosPromise<Array<User>> {
            return localVarFp.userControllerGetIndividuals(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} item 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userControllerGetProfile(item: string, options?: any): AxiosPromise<User> {
            return localVarFp.userControllerGetProfile(item, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userControllerGetSystemUsers(options?: any): AxiosPromise<Array<User>> {
            return localVarFp.userControllerGetSystemUsers(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} item 
         * @param {UpdateProfileDto} updateProfileDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userControllerUpdateUserProfile(item: string, updateProfileDto: UpdateProfileDto, options?: any): AxiosPromise<User> {
            return localVarFp.userControllerUpdateUserProfile(item, updateProfileDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * UsersControllerApi - object-oriented interface
 * @export
 * @class UsersControllerApi
 * @extends {BaseAPI}
 */
export class UsersControllerApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersControllerApi
     */
    public userControllerGetAgents(options?: AxiosRequestConfig) {
        return UsersControllerApiFp(this.configuration).userControllerGetAgents(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersControllerApi
     */
    public userControllerGetIndividuals(options?: AxiosRequestConfig) {
        return UsersControllerApiFp(this.configuration).userControllerGetIndividuals(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} item 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersControllerApi
     */
    public userControllerGetProfile(item: string, options?: AxiosRequestConfig) {
        return UsersControllerApiFp(this.configuration).userControllerGetProfile(item, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersControllerApi
     */
    public userControllerGetSystemUsers(options?: AxiosRequestConfig) {
        return UsersControllerApiFp(this.configuration).userControllerGetSystemUsers(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} item 
     * @param {UpdateProfileDto} updateProfileDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersControllerApi
     */
    public userControllerUpdateUserProfile(item: string, updateProfileDto: UpdateProfileDto, options?: AxiosRequestConfig) {
        return UsersControllerApiFp(this.configuration).userControllerUpdateUserProfile(item, updateProfileDto, options).then((request) => request(this.axios, this.basePath));
    }
}


